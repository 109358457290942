import { FC } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import styled, { css } from 'styled-components'

interface ILink {
	color?: string
	underline?: boolean // underline on hover & active
}

const link = (tag) => styled(tag)<ILink>`
	// define property needed for animation underline
	@property --offset {
		syntax: '<length>';
		inherits: false;
		initial-value: 0;
	}

	position: relative;
	display: inline-block;
	color: ${({ color, theme }) => color || theme.color.black};
	text-underline-offset: var(--offset, 0);
	text-decoration: underline 2px rgba(0, 255, 255, 0) !important;
	transition: color ease 0.3s, --offset 0.3s, text-decoration-color 0.3s;
	cursor: pointer;

	&.active,
	&:hover {
		color: ${({ theme }) => theme.color.blue};

		${({ underline }) =>
			underline &&
			css`
				--offset: 0.3em;
				text-decoration-color: ${({ theme }) => theme.color.blue}!important;
			`}
	}
`
export const TextLink = link('span')
export const ExternalLink: FC<ILink & any> = (props) => (
	<TextLink as={'a'} {...props}>
		{props.children}
	</TextLink>
)
export const InternalLink: FC<ILink & NavLinkProps<any>> = ({ children, ...props }) => {
	let Tag = NavLink
	// @ts-ignore
	if (props.to.constructor === Object && props.to.hash) {
		Tag = HashLink
	}
	return (
		<ExternalLink as={Tag} underline={true} {...props}>
			{children}
		</ExternalLink>
	)
}
