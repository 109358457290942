import { Logo } from 'assets/icon'
import { LANDING_MAIN } from 'config/exLinks'
import { FC } from 'react'
import styled from 'styled-components'
import { ExternalLink } from 'ui/Link'

const Contact = styled.div`
	margin: 30px 0 0 0;
`
const Wrapper = styled.div`
	svg {
		font-size: 5em;
		margin: 0 0 20px 0;
	}
`

interface IProps {
	withLogo?: boolean
	withContact?: boolean
}
export const AddressBox: FC<IProps> = ({ withLogo = false, withContact = false }) => {
	return (
		<Wrapper>
			{withLogo ? <Logo /> : null}
			<div>
				Bitclear Aktiengesellschaft
				<br />
				P.O. Box 534
				<br />
				Industriestrasse 26
				<br />
				9491 Ruggell, Liechtenstein
			</div>
			{withContact ? (
				<Contact>
					<ExternalLink href="tel:+4233771199" underline={true}>
						+423 377 11 99
					</ExternalLink>
					<br />
					<ExternalLink href={LANDING_MAIN} underline={true}>
						{LANDING_MAIN}
					</ExternalLink>
					<br />
					<ExternalLink href="mailto:info@bitclear.li" underline={true}>
						info@bitclear.li
					</ExternalLink>
				</Contact>
			) : null}
		</Wrapper>
	)
}
