import { LocationDescriptor } from 'history'
import { FC } from 'react'
import styled, { css } from 'styled-components'
import { MobileView } from 'styles/mixins/MobileView'
import { ExternalLink, InternalLink } from 'ui/Link'

const Links = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 40px;

	> * {
		display: inline-block;
		margin-bottom: 20px;

		${MobileView(css`
			margin-bottom: 15px;
		`)}
	}
`

export interface ILinks {
	label: string
	to?: LocationDescriptor<any>
	href?: string
}

export const FooterLinks: FC<{ links: ILinks[] }> = ({ links }) => (
	<Links>
		{links.map((item, index) => {
			if (item.to) {
				return (
					<InternalLink to={item.to} key={index} exact>
						{item.label}
					</InternalLink>
				)
			} else if (item.href) {
				return (
					<ExternalLink href={item.href} key={index} target="_self" rel="nofollow noreferrer" underline={true}>
						{item.label}
					</ExternalLink>
				)
			}
		})}
	</Links>
)
