import { FC } from 'react'
import styled, { css } from 'styled-components'
import { MobileView } from 'styles/mixins/MobileView'
import { IThemeScreen } from 'styles/themeStyles'
import { PageContent } from 'ui/PageContent'

interface ISpace {
	padding: string
	mobile?: {
		padding: string
		mobileScreen?: keyof IThemeScreen
	}
}

export const Space = styled.div<ISpace>`
	width: 100%;
	height: 0;
	padding: 0 0 ${({ padding }) => padding} 0;

	${({ mobile }) =>
		mobile &&
		MobileView(
			css`
				padding: 0 0 ${mobile.padding} 0;
			`,
			{ screen: mobile.mobileScreen || 'sm' },
		)}
`
export const PageContentSpace: FC<ISpace> = ({ padding, mobile }) => (
	<PageContent>
		<Space padding={padding} mobile={mobile} />
	</PageContent>
)
