import styled from 'styled-components'
import { IThemeColors } from 'styles/themeStyles'

interface ILine {
	color?: keyof IThemeColors
	fullWidth?: boolean
	width?: string
	height?: string
	margin?: string
}

export const Hr = styled.div<ILine>`
	width: ${({ width }) => width || '100%'};
	height: 0;
	border-width: ${({ height }) => height || '1px'} 0 0 0;
	border-style: solid;
	margin: ${({ margin }) => margin || 'margin'};
	border-color: ${({ color, theme }) => theme.color[color || 'grayLight']};
	max-width: ${({ theme, fullWidth }) => (fullWidth ? 'none' : theme.screen.hq)};
`
