import { Bfx, Bpay, Linkedin } from 'assets/icon'
import { AddressBox } from 'components/AddressBox'
import { FooterLinks, ILinks } from 'components/pageFooter/footerLinks'
import { ENV } from 'config/env'
import { FX_LOGIN, KYC_REGISTER_FX, KYC_REGISTER_PAY, LINKED_IN, PAY_LOGIN } from 'config/exLinks'
import { VERSION } from 'config/system'
import { SuperAdminOnly } from 'modules/ui/SuperAdminOnly'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { PATH, PATH_FX, PATH_PAY } from 'Routes'
import styled, { css } from 'styled-components'
import { FontSizeResponsive } from 'styles/mixins/FontSizeResponsive'
import { DesktopView, MobileView } from 'styles/mixins/MobileView'

import { themeStyles } from 'styles/themeStyles'
import { Hr } from 'ui/Hr'
import { PageContent } from 'ui/PageContent'
import { Space } from 'ui/Space'

interface IData {
	links: ILinks[]
	bfx: ILinks[]
	bpay: ILinks[]
	desc: string
	copy: string
}

const data: IData = {
	bpay: [
		{
			label: 'Payment Gate',
			to: PATH.PAY,
		},
		{
			label: 'API',
			href: ENV.BPAY_API_DOC,
		},
		{
			label: 'Log in',
			href: PAY_LOGIN,
		},
		{
			label: 'Register',
			href: KYC_REGISTER_PAY,
		},
		{
			label: 'Affiliate',
			to: PATH_PAY.AFFILIATE,
		},
	],
	bfx: [
		{
			label: 'Exchange',
			to: PATH.FX,
		},
		{
			label: 'How it works',
			to: {
				pathname: PATH.FX,
				hash: '#' + PATH_FX.HASH_HOW_IT_WORKS,
			},
		},
		{
			label: 'Glossary',
			to: PATH_FX.GLOSSARY,
		},
		{
			label: 'Affiliate',
			to: PATH_FX.AFFILIATE,
		},
		{
			label: 'Log in',
			href: FX_LOGIN,
		},
		{
			label: 'Register',
			href: KYC_REGISTER_FX,
		},
	],
	links: [
		{
			label: 'About us',
			to: PATH.ABOUT_US,
		},
		{
			label: 'Contact',
			to: PATH.CONTACT,
		},
		{
			label: 'Legal info',
			to: PATH.LEGAL_INFO,
		},
		{
			label: 'Impressum',
			to: PATH.IMPRESSUM,
		},
		{
			label: 'Terms & Conditions',
			to: PATH.TERMS_CONDITIONS,
		},
		{
			label: 'Privacy policy',
			to: PATH.PRIVACY_POLICY,
		},
		{
			label: 'FAQ',
			to: PATH.FAQ,
		},
	],
	desc: 'Bitclear Payment Gate and Bitclear Exchange are owned by <strong>Bitclear AG.</strong>',
	copy: `Copyright © ${new Date().getFullYear()} Bitclear.li`,
}

const Left = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-bottom: 60px;
	${MobileView(css`
		margin-bottom: 0;
	`)}

	& > div {
		width: 50%;
		margin: 0 0 20px 0;
	}

	${DesktopView(
		css`
			flex-wrap: nowrap;

			& > div {
				width: auto;
			}
		`,
		{ screen: 'lg' },
	)}
	${DesktopView(
		css`
			& > div {
				width: 190px;
			}
		`,
		{ screen: 'xl' },
	)}
`
const Desc = styled.div`
	margin: 0 0 20px 0;
`
const Right = styled.div`
	width: 100%;
	max-width: 420px;
	margin-bottom: 60px;
	${MobileView(css`
		margin-bottom: 30px;
	`)}
`
const TopSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	padding: 20px 0;

	${DesktopView(
		css`
			align-items: flex-start;
			flex-direction: row;
		`,
		{ screen: 'xl' },
	)}
`

const Social = styled.div`
	font-weight: ${({ theme }) => theme.weight.extraBold};

	svg {
		margin-top: 20px;
		color: ${({ theme }) => theme.color.gray};
		font-size: 2em;
	}
`
const Copy = styled.div`
	${FontSizeResponsive({ size: 'small' })}
`
const ProductLogo = styled.div`
	height: 65px;

	svg {
		font-size: 4.5em;
	}
`

const ColumnLinks = () => (
	<div>
		<ProductLogo />
		<FooterLinks links={data.links} />
	</div>
)
const ColumnSocial = () => (
	<div>
		<ProductLogo />
		<Social>
			Follow us!
			<br />
			<a href={LINKED_IN} target="_blank" rel="noreferrer">
				<Linkedin />
			</a>
		</Social>
	</div>
)

const ColumnBfx = () => (
	<div>
		<ProductLogo>
			<Link to={PATH.FX}>
				<Bfx />
			</Link>
		</ProductLogo>
		<FooterLinks links={data.bfx} />
	</div>
)
const ColumnBpay = () => (
	<div>
		<ProductLogo>
			<Link to={PATH.PAY}>
				<Bpay />
			</Link>
		</ProductLogo>
		<FooterLinks links={data.bpay} />
	</div>
)

const PageFooter: FC = () => {
	return (
		<PageContent background={themeStyles.color.grayWhite}>
			<Space padding="5%" />
			<TopSection>
				<Left>
					<ColumnBfx />
					<ColumnBpay />
					<ColumnLinks />
					<ColumnSocial />
				</Left>
				<Right>
					<ProductLogo />
					<Desc dangerouslySetInnerHTML={{ __html: data.desc }} />
					<AddressBox withLogo />
				</Right>
			</TopSection>
			<Hr />
			<Space padding="6%" />
			<Copy>
				<div>{data.copy}</div>
				<SuperAdminOnly>Version: {VERSION}</SuperAdminOnly>
			</Copy>
			<Space padding="6%" />
		</PageContent>
	)
}

export default PageFooter
