import { ENV } from 'config/env'

// KYC
export const KYC_MAIN = ENV.KYC_URL
export const KYC_REGISTER = ENV.KYC_URL + '/user/register'
export const KYC_REGISTER_FX = ENV.KYC_URL + '/user/register?product=bfx'
export const KYC_REGISTER_PAY = ENV.KYC_URL + '/user/register?product=bpay'

// FX
export const FX_MAIN = ENV.BFX_URL
export const FX_LOGIN = ENV.BFX_URL + '/login'

// PAY
export const PAY_LOGIN = ENV.BPAY_LOGIN_URL

// LANDING
export const LANDING_MAIN = 'https://www.bitclear.li'
export const LINKED_IN = 'https://www.linkedin.com/company/bitclear'
